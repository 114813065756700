<script>
import { useAuthStore } from "../stores/authStore";
export default {
    data(){
        return{
            permisos: useAuthStore().getPermisos,
        }
    },
    methods:{
        hascan(permiso){
            // const authStore = useAuthStore();
            // this.permisos = authStore.getPermisos; 
            if(this.permisos)
                return this.permisos.includes(permiso); 
            else
                return false;
        },
        isTokenValid(){
            return useAuthStore().isTokenValid();
        },
    },
};
</script>