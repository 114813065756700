<template>
	<div class="login-body">
		<!-- <div class="login-image"></div> -->
		<div class="card login-panel p-fluid">
			<div class="login-panel-content">
				<div class="grid mt-4">
					<Card>						
						<template v-slot:content>
							<div class="col-12 text-center">
								<img src="layout/images/cepi/logo-cepi-web-dark.png" class="img-responsive" alt="GADCH" height="105"/>
							</div>
							<div class="col-12">
								<h6 class="text-center">SIAC</h6>
								<!-- <span class="guest-sign-in">Iniciar Sesión</span> -->
							</div>
							<div class="col-12" style="text-align:left">
								<label class="login-label">Usuario ó Correo</label>
								<div class="login-input">
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon">
											<i class="pi pi-user"></i>
										</span>
										<InputText v-model="usuario" type="text" placeholder="Usuario ó Correo"/>								
									</div>							
								</div>
							</div>
							<div class="col-12" style="text-align:left">
								<label class="login-label">Contraseña</label>
								<div class="login-input">
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon">
											<i class="pi pi-unlock"></i>
										</span>
										<InputText v-model="password" type="password" placeholder="Contraseña" @keyup.enter="login"/>
									</div>
								</div>						
							</div>
							<div class="col-12 md:col-12 button-pane">								
								<Button label="Iniciar Sesión" @click="login()" :loading="isLoading"/>								
							</div>
							<!-- <div class="col-12 md:col-6 link-pane">
								<button class="p-link">Forget Password?</button>
							</div> -->	
						</template>
					</Card>
					<div class="col-12">
						<small class="text-center">© 2023 SIAC - CEPI v1.0.0</small>
					</div>
				</div>
			</div>
		</div>
		<Toast />
	</div>
</template>

<script>
	import { useAuthStore }	from '../stores/authStore';
	import { AuthService } from '../service/AuthService';

	export default {
		name: 'Login',
		components: {
			
		},
		setup() {
			const authStore = useAuthStore();
			// const error = computed(() => authStore.error);
			// const isLoading = computed(() => authStore.isLoading);
			return {
				authStore,
			}
		},
		data() {
			return {
				usuario: null,
				password: null,	
				isLoading: false,			
			}
		},
		mounted() {
			if(this.authStore.isTokenValid()){
				this.$router.push('/');
			}
		},
		methods: {
			async login() {
				this.isLoading = true;
				try {
					const response = await AuthService.login(this.usuario, this.password);
					// console.log(response.data)					

					if(response.data?.token){
						this.$toast.add({severity:'success', summary: 'Mensaje', detail:'Bienvenido... iniciando sesión', life: 3000});
						this.$router.push('/');
					}
				} catch (error) {
					// console.log(error.response);
					if(error.response?.data.message){
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: `Ocurrió un error. ${error.response?.data.message}`, life: 3000});				
					}else{
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: `Ocurrió un error al conectarse con el servidor. ${error.message}`, life: 3000});				
					}
					this.isLoading = false;
				}

				this.isLoading = false;				
			},
		},
	}
</script>

<style scoped>

</style>
