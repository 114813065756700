import axios from 'axios';
import router from '../router';
import { useAuthStore } from "../stores/authStore";

import Loading from '../components/Loading.vue';
import { createApp } from 'vue';

const loadingComponent = createApp(Loading);
const loadingInstance = loadingComponent.mount(document.createElement('div'));
document.body.appendChild(loadingInstance.$el);

const apiInstance = axios.create({
    baseURL : process.env.VUE_APP_URL_API,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

// Intercepta peticion a la API
apiInstance.interceptors.request.use((config) => {
    const authStore = useAuthStore();    
    loadingInstance.show();
    if (authStore.hasToken()) {
        if(authStore.isTokenValid()){
            config.headers.Authorization = 'Bearer ' + authStore.getToken;        
        }else{
            router.push('/login');
        }
    }
    return config;
}, (error) => {
    loadingInstance.hide();
    return Promise.reject(error);
});

// Intercepta respuestas de la API
apiInstance.interceptors.response.use((response) => {
    loadingInstance.hide();
    // Verifica si la respuesta contiene un nuevo token de autenticación
    const authStore = useAuthStore();
    if(response.data?.token){        
        // Actualiza o Almacena el token de autenticación            
        authStore.setToken(response.data.token);
        authStore.setUser(response.data.usuario);
        authStore.setRol(response.data.rol);
        authStore.setPermisos(response.data.permisos);
        authStore.setIsLogin(true);    
    }   
    return response;
}, (error) => {
    loadingInstance.hide();
    // Maneja los errores de autenticación, como tokens inválidos o expirados
    if (error.response && error.response.status === 401) {
        // Realiza las acciones necesarias, como redirigir a la página de inicio de sesión o limpiar el almacenamiento local
    }
    return Promise.reject(error);
});

export {
    apiInstance
}